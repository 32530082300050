/* global Swiper */
import domReady from '@roots/sage/client/dom-ready';
import 'flowbite';
/**
 * Application entrypoint
 */
domReady(async () => {
  initNav();
  initSearchForm();

  try {
    const logoType = document.querySelector('[data-name="logo-type"]');
    const logoNoType = document.querySelector('[data-name="logo-notype"]');
    createObserver(logoType, (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          logoNoType.classList.add('opacity-0');
          logoType.classList.remove('opacity-0');
        } else {
          logoNoType.classList.remove('opacity-0');
          logoType.classList.add('opacity-0');
        }
      });
    });
  } catch (error) {
    console.log('error', error);
  }

  try {
    createSwiper();
    initSwiperLinks();
  } catch (error) {
    console.log('Swiper error', error);
  }
});

function createObserver(element, callback) {
  let distance = '-170px';
  if (document.querySelector('.admin-bar') !== null) {
    distance = '-202px'; // add 32px to distance if admin bar is visible
  }
  const observer = new IntersectionObserver(callback, {
    rootMargin: distance,
    threshold: 0,
  });

  observer.observe(element);
}

function initNav() {
  // const navIcon = document.querySelectorAll('[data-id="av-nav-icon"]');
  const headerContainer = document.querySelector(
    '[data-id="header-container"]',
  );

  const navDialog = document.getElementById('av-nav-dialog');
  const navClose = document.getElementById('av-nav-close');

  headerContainer.addEventListener('click', (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (path) {
      const navIcon = path.find((el) => el?.dataset?.id === 'av-nav-icon');
      if (navIcon) {
        navDialog.showModal();
        navDialog.classList.remove('opacity-0');
        navDialog.classList.add(
          'transition-opacity',
          'duration-700',
          'opacity-100',
        );
      }
    }
  });

  navClose.addEventListener('click', () => {
    navDialog.close();
    navDialog.classList.remove('opacity-100');
    navDialog.classList.add('opacity-0');
  });
}

function initSearchForm() {
  const searchformDialog = document.getElementById('av-searchFormDialog');
  const searchIcon = document.getElementById('av-icon-search');

  document.addEventListener('keydown', (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
      openSearchForm(searchformDialog);
    }
  });

  searchIcon.addEventListener('click', () => {
    openSearchForm(searchformDialog);
  });
}

function openSearchForm(searchformDialog) {
  searchformDialog.showModal();
  searchformDialog.classList.remove('opacity-0');
  searchformDialog.classList.add(
    'transition-opacity',
    'duration-700',
    'opacity-100',
  );
}

function createSwiper() {
  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('.swiper', {
    // Optional parameters
    // direction: 'vertical',
    loop: true,
    speed: 700,
    parallax: true,

    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },

    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 0,

    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },

    autoplay: {
      delay: 5000, // TODO: get delay from the wordpress options
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
      loadOnTransitionStart: true,
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });
}

function initSwiperLinks() {
  document.querySelectorAll('.swiper-slide').forEach((slide) => {
    slide.addEventListener('click', () => {
      const url = slide.dataset.link;
      const target = slide.dataset.target;
      if (url) {
        if (target === '_blank') {
          window.open(url, '_blank');
        } else {
          console.log('url', url);
          window.location.href = url;
        }
      }
    });
  });
}

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
